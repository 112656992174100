<template>
  <div class="types-projet-setting">
    <div class="header pb-3">
      <div class="style-title-setting-global">
        {{
          $i18n.locale == 'fr'
            ? 'Gestion des Types des projets'
            : 'Project Type Management'
        }}
      </div>
      <v-btn dark color="#5C2DD3" @click.stop="handleClickBtnAddTypeProjet">
        <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
        {{
          $i18n.locale == 'fr' ? 'Ajouter type de projet' : 'Add Project Type'
        }}
      </v-btn>
    </div>
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <v-list
        dense
        class="list-ensemble mt-3"
        v-if="getProjectsTypes && getProjectsTypes.length"
      >
        <v-list-item
          color="#5C2DD3"
          class="list-ensemble-item mr-3"
          v-for="(type, index) in getProjectsTypes"
          :key="type.id"
          :class="{
            'border-top-solid': index == 0
          }"
        >
          <v-list-item-content>
            <v-list-item-title>
              <div class="font-text font-sz-14 bold-700">
                {{ type.name }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(type)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(type)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{
            $i18n.locale == 'fr' ? 'Aucune type de projet' : 'No project type'
          }}
        </div>
      </div>
      <div
        class="footer-style-table"
        v-if="getProjectsTypes && getProjectsTypes.length"
      >
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- Add Type_Projet Modal -->
    <v-dialog
      v-model="addProjetsTypesModal"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Ajouter un type de projet'
                : 'Add project type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('addProjetsTypesModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addProjetsTypesModal" class="mt-2">
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="typeToAdd.name"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field
              ></v-col>
            </v-row>

            <!-- <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('search')"
                  item-color="#5C2DD3"
                  v-model="typeToAdd.header_field"
                  :items="getCustomFieldProject"
                  :loading="false"
                  :persistent-placeholder="true"
                  :label="$t('header_field')"
                  item-text="name"
                  dense
                  item-value="id"
                  :no-data-text="$t('noDataOption')"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete
              ></v-col>
            </v-row> -->
            <v-row>
              <v-col>
                <v-select
                  :label="$t('theme')"
                  dense
                  v-model="typeToAdd.theme"
                  :items="themeList"
                  outlined
                  required
                  item-text="text"
                  item-value="value"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#5C2DD3"
                  :persistent-placeholder="true"
                  item-color="#5C2DD3"
                  :rules="[v => !!v || $t('theme') + ' ' + $t('msgOblg')]"
                ></v-select
              ></v-col>
            </v-row>
            <v-row>
              <v-col class="ml-3">
                <v-switch
                  class="input-checkbox switch-bottom label-switch"
                  :label="$t('one_step')"
                  v-model="typeToAdd.one_step"
                  color="#5C2DD3"
                >
                </v-switch
              ></v-col>
              <v-col>
                <v-switch
                  class="input-checkbox switch-bottom label-switch"
                  :label="$t('one_category_projet')"
                  v-model="typeToAdd.use_project"
                  color="#5C2DD3"
                >
                </v-switch
              ></v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <div class="mb-3">Champs personnalisés :</div>
                <div
                  class="list-fildes-type-projet"
                  v-if="getCustomFieldProject && getCustomFieldProject.length"
                >
                  <v-switch
                    class="input-checkbox switch-bottom label-switch"
                    v-for="(item, index) in getCustomFieldProject"
                    :key="index"
                    :value="item.id"
                    color="#5C2DD3"
                    :label="item.name"
                    v-model="typeToAdd.selected[index]"
                  >
                  </v-switch>
                </div>
                <div class="color-crm font-text font-sz-12 text-center">
                  Aucun champ personnalisés
                </div>
              </v-col>
            </v-row> -->
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsTypesLoading && !initLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getProjectsTypesError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsTypesError)">
                <li v-for="(e, index) in getProjectsTypesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsTypesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            :disabled="initLoading"
            @click.prevent.stop="handlTypeProjet"
            :loading="getProjectsTypesLoading && !initLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('addProjetsTypesModal')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Add Type_Projet Modal -->
    <!-- Delete Type_Projet Modal -->
    <v-dialog v-model="ModalDelete" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale == 'fr'
                ? 'Supprimer un type de projet'
                : 'Delete project type'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDelete')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale == 'fr' ? 'le type' : 'the type'
              })
            }}
            <strong class="gras">
              {{ typeToDelete ? typeToDelete.name : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsTypesLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getProjectsTypesError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsTypesError)">
                <li v-for="(e, index) in getProjectsTypesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsTypesError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="deletedTypeProjet"
            :loading="getProjectsTypesLoading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDelete')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete Type_Projet Modal -->
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      typeToAdd: {
        name: null,
        one_step: false,
        use_project: 0,
        selected: [],
        theme: 'standard',
        header_field: null
      },
      typeToUpdate: null,
      typeToDelete: null,
      initLoading: true,
      addProjetsTypesModal: false,
      ModalDelete: false,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ]
    }
  },
  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchCustomFieldProject',
      'addProjectType',
      'deleteProjectType',
      'resetErrorProjectType'
    ]),
    pagination(pagination) {
      this.page = pagination
      this.fetchProjetsTypes({
        page: this.page,
        per_page: this.per_page
      })
    },
    changePerPage() {
      this.fetchProjetsTypes({
        page: this.page,
        per_page: this.per_page
      })
    },
    handleClickBtnAddTypeProjet() {
      this.addProjetsTypesModal = true
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'addProjetsTypesModal') {
        this.$refs.addProjetsTypesModal.resetValidation()
      }
      this.resetModal()
    },

    handleUpdateClick(type) {
      this.$router.replace('/setting/projects-types/' + type.id)
    },
    handleDeleteClick(type) {
      this.typeToDelete = { ...type }
      this.ModalDelete = true
    },
    resetModal() {
      this.typeToAdd = {
        name: null,
        one_step: false,
        use_project: 0,
        selected: [],
        theme: 'standard',
        header_field: null
      }
      this.typeToUpdate = null
      this.typeToDelete = null
      this.resetErrorProjectType()
    },
    async deletedTypeProjet() {
      const response = await this.deleteProjectType(this.typeToDelete.id)
      if (response) {
        this.closeDialog('ModalDelete')
        this.fetchProjetsTypes({ page: this.page, per_page: this.per_page })
      }
    },
    async handlTypeProjet() {
      if (this.$refs.addProjetsTypesModal.validate()) {
        const response = await this.addProjectType(this.typeToAdd)
        if (response) {
          this.fetchProjetsTypes({ page: this.page, per_page: this.per_page })
          this.closeDialog('addProjetsTypesModal')
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsTypes',
      'getCustomFieldProject',
      'getProjectsTypesLoading',
      'getProjectsTypesError',
      'countPaginationTypeProjet'
    ]),
    themeList() {
      return [
        { value: 'standard', text: this.$t('default_theme') },
        { value: 'immobilier', text: this.$t('immo_theme') }
      ]
    },
    totalPages() {
      if (this.countPaginationTypeProjet) {
        return Math.ceil(this.countPaginationTypeProjet / this.per_page)
      }
      return this.countPaginationTypeProjet
    }
  },
  async mounted() {
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes({ page: this.page, per_page: this.per_page })
    }
    // await this.fetchCustomFieldProject()
    this.initLoading = false
  }
}
</script>

<style lang="scss" scoped>
.types-projet-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 0px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 277px) !important;
    height: calc(100vh - 277px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }

  .icon-exclamation {
    text-decoration: line-through;
    text-decoration-color: #e73e01;
  }
  //   .content-members {
  //     padding-top: 8px;
  //   }
  .list-member {
    margin: 9px 0px;
    li {
      margin-bottom: 6px;
    }
  }
  .card-list {
    box-shadow: unset !important;
  }
}
.stade-sous-stade-flex {
  position: relative;
  margin-left: 25px;
  margin-top: 10px;
  margin-bottom: 10px;

  label {
    font-size: 12px;
    font-weight: bold;
  }
}
.project-type-sub-tree {
  border-bottom: 1px solid #5c2dd3;
  border-left: 1px solid #5c2dd3;
  position: absolute;
  width: 8px;
  height: 83px;
  top: 183px;
  left: 40px;
  background: #fff;
  z-index: 2;

  &.child {
    width: 13px;
    height: 29px;
    top: 158px;
    left: 35px;
    z-index: 10;
  }
}
.titre {
  padding-top: 20px;
}
.type {
  font-weight: normal;
  text-transform: capitalize;
  font-size: 12px;
  color: #317ae2;
}
.check-switch {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
  .switch {
    margin-left: 20px;
  }
}
.icon-exclamation {
  text-decoration: line-through;
  text-decoration-color: #e73e01;
}

.form-actions {
  padding-top: 20px;
}
.defilement {
  // position: relative;
  height: calc(100vh - 223px) !important;
  max-height: calc(100vh - 223px) !important;
  overflow: auto;
}

.form-control,
.custom-select:disabled {
  border: 1px solid #e8e8e8;
}
.list-fildes-type-projet {
  max-height: calc(100vh - 319px) !important;
  height: calc(100vh - 319px) !important;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0px 0px;
  cursor: pointer;
}
.list-fildes-type-projet::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.list-fildes-type-projet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 4px;
  background-color: rgba(238, 238, 238, 1) !important;
}
.list-fildes-type-projet::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(189, 189, 189, 1);
}
</style>

<style lang="scss">
.types-projet-setting {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
</style>
